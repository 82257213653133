import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class ApisService {

    appRoot: string = environment.apiUrl;
    private selectedCountry = new BehaviorSubject<any>(null);

    constructor(private http: HttpClient) { }

    getHttpHeader() {
        // const language = localStorage.getItem('language') || 'en';
        const language = 'en';
        const header = new HttpHeaders()
        header.set('platform','Bonbell@123456789' )
        header.set('Accept-Language', language);
        header.set('lang', language);
        return header;
    }

    registerUser(body: any) {
        return this.http.post<any>(`${this.appRoot}saas/v1/self-registration/register`, body,{ headers: this.getHttpHeader()}).pipe(this.handleHttpErrors())
    }

    verifyUser(body: any) {
        return this.http.post<any>(`${this.appRoot}saas/v1/self-registration/verify`, body,{ headers: this.getHttpHeader()}).pipe(this.handleHttpErrors())
    }

    getCountries() {
      return this.http.get<any>(`${this.appRoot}saas/v1/vendor/constants?keys=countries`,{ headers: this.getHttpHeader()}).pipe(this.handleHttpErrors())
    }

    getPlans(countryId:any){
      return this.http.get<any>(`${this.appRoot}saas/v1/plans?country_id=${countryId}`,{ headers: this.getHttpHeader()})
        .pipe(this.handleHttpErrors())
        .pipe(map(plans => {
          const monthlyPlans = plans.data.filter((plan:any) => {return plan.duration === 30});
          const yearlyPlans = plans.data.filter((plan:any) => {return plan.duration === 365});
          return { monthlyPlans, yearlyPlans };
        }));
    }

    sendContactUs(body: any) {
      return this.http.post<any>(`${this.appRoot}saas/v1/contact-us`, body,{ headers: this.getHttpHeader()}).pipe(this.handleHttpErrors())
    }

    setSelectedCountry(country: any) {
      this.selectedCountry.next(country);
    }

    getSelectedCountry(): Observable<any> {
        return this.selectedCountry.asObservable();
    }

    handleHttpErrors() {
        return (source: Observable<any>) => {
          return source.pipe(
            catchError((error) => {
              if (error.error && error.error.errors) {
                const firstErrorKey = Object.keys(error.error.errors)[0];
                const firstError = error.error.errors[firstErrorKey][0];
      
                const formattedError = new Error(
                  `${firstError}`
                );
                return throwError(formattedError);
              } else {
                return throwError(error);
              }
            })
          );
        };
      }

    }








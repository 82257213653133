import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgwWowService } from 'ngx-wow';
import { ApisService } from '../services/api.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  language: string = localStorage.getItem('language') || 'en'
  countries: any;
  modalRef: any;
  @ViewChild('modal') modal:any;
  selectedCountry:any='';
  isSubmitted: boolean=false;
  
  constructor(
    private wowService: NgwWowService,
    private translateService: TranslateService,
    private route:ActivatedRoute,
    private router:Router,
    @Inject(DOCUMENT) private document: Document,
    private _apiService: ApisService,
    private modalService: BsModalService
    ) {
    this.changeLangage(this.language)
    this.wowService.init();
  }

  ngOnInit(): void {
    this.getCountries();
    if(this.route.snapshot.params['lang']){
      this.router.navigate([`home/${this.route.snapshot.params['lang']}`])
    }
  }

  getCountries() {
    this._apiService.getCountries().subscribe((res) => {
      this.countries = res.data.countries;
      this.modalRef = this.modalService.show(this.modal,{
        backdrop:'static',
        keyboard:false,
      });
    });
  }

  onModalClose(){
    if(!this.selectedCountry){
      this.isSubmitted = true;
      return
    }
    this.modalRef.hide();
    const country = this.countries.find((country:any) => country.id == this.selectedCountry);
    this._apiService.setSelectedCountry(country);
  }

  changeLangage(lang: string) {
    let htmlTag = this.document.getElementsByTagName(
      "html"
    )[0] as HTMLHtmlElement;
    htmlTag.dir = lang === "ar" ? "rtl" : "ltr";
    this.translateService.setDefaultLang(lang);
    this.translateService.use(lang);
    this.changeCssFile(lang);
  }

  changeCssFile(lang: string) {
    let headTag = this.document.getElementsByTagName(
      "head"
    )[0] as HTMLHeadElement;
    let existingLink = this.document.getElementById(
      "langCss"
    ) as HTMLLinkElement;

    let bundleName = lang === "ar" ? "arabicStyle.css" : "englishStyle.css";

    if (existingLink) {
      existingLink.href = bundleName;
    } else {
      let newLink = this.document.createElement("link");
      newLink.rel = "stylesheet";
      newLink.type = "text/css";
      newLink.id = "langCss";
      newLink.href = bundleName;
      headTag.appendChild(newLink);
    }
  }
}

<router-outlet></router-outlet>

<ng-template #modal>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Please Select A Country</h4>
      <!-- <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
      </button> -->
    </div>
    <div class="modal-body">
      <label translate>Country</label>
      <select class="form-control form-select" [(ngModel)]="selectedCountry">
        <option value="">Select</option>
        <option [value]="c.id" *ngFor="let c of countries">{{language=='en'?c.name:c.name_ar}}</option>
      </select>
      <span class="text-danger" *ngIf="isSubmitted && !selectedCountry">Please Select A Country</span>
      <div class="d-flex justify-content-end mt-3">
        <button class="btn btn-primary" (click)="onModalClose()">Select</button>
      </div>
    </div>
  </ng-template>